export const DojiIcon = ({ ...props }) => (
  <svg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.44921 1.35254V5.40228C5.86428 4.71957 5.02438 4.39373 4.0345 4.39373C1.75478 4.39373 0 6.06948 0 8.69173C0 11.314 1.75478 12.9897 4.0345 12.9897C5.11437 12.9897 5.96927 12.6329 6.55419 11.9036V12.8656H8.78892V1.35254H6.44921ZM4.43945 11.0037C3.2696 11.0037 2.36971 10.1347 2.36971 8.69173C2.36971 7.24872 3.2696 6.37981 4.43945 6.37981C5.59431 6.37981 6.4942 7.24872 6.4942 8.69173C6.4942 10.1347 5.59431 11.0037 4.43945 11.0037Z"
      fill="#0C0C0C"
    />
    <path
      d="M14.7939 12.9897C17.3586 12.9897 19.2184 11.2054 19.2184 8.69173C19.2184 6.1781 17.3586 4.39373 14.7939 4.39373C12.2292 4.39373 10.3545 6.1781 10.3545 8.69173C10.3545 11.2054 12.2292 12.9897 14.7939 12.9897ZM14.7939 11.0037C13.6241 11.0037 12.7242 10.1347 12.7242 8.69173C12.7242 7.24872 13.6241 6.37981 14.7939 6.37981C15.9638 6.37981 16.8487 7.24872 16.8487 8.69173C16.8487 10.1347 15.9638 11.0037 14.7939 11.0037Z"
      fill="#0C0C0C"
    />
    <path
      d="M20.1824 15.9999C22.1321 15.9999 23.227 14.7741 23.227 12.8501V4.51786H20.8873V12.8656C20.8873 13.688 20.5123 14.0759 19.9274 14.0759C19.5374 14.0759 19.1925 13.9673 18.9375 13.7811L18.3076 15.5344C18.7575 15.8602 19.4625 15.9999 20.1824 15.9999Z"
      fill="#0C0C0C"
    />
    <path d="M25.3813 12.8656H27.721V4.51786H25.3813V12.8656Z" fill="#0C0C0C" />
    <path
      d="M22.1311 2.96265C21.3403 2.96265 20.6992 2.29942 20.6992 1.4813C20.6992 0.663173 21.3403 -4.89048e-05 22.1311 -4.89406e-05C22.9219 -4.89763e-05 23.563 0.663173 23.563 1.4813C23.563 2.29942 22.9219 2.96265 22.1311 2.96265Z"
      fill="#11A856"
    />
    <path
      d="M25.1367 1.48135C25.1367 0.663222 25.7778 0 26.5686 0C27.3594 0 28.0005 0.663222 28.0005 1.48135C28.0005 2.29947 27.3594 2.9627 26.5686 2.9627C25.7778 2.9627 25.1367 2.29947 25.1367 1.48135Z"
      fill="#11A856"
    />
  </svg>
);

export const HamburgerIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
    <path d="M0 0H18V2H0V0ZM0 7H18V9H0V7ZM0 14H18V16H0V14Z" fill="#646464" />
  </svg>
);

export const PerformanceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M1.33398 8.66667H5.33398V14H1.33398V8.66667ZM10.6673 5.33333H14.6673V14H10.6673V5.33333ZM6.00065 2H10.0007V14H6.00065V2ZM2.66732 10V12.6667H4.00065V10H2.66732ZM7.33398 3.33333V12.6667H8.66732V3.33333H7.33398ZM12.0007 6.66667V12.6667H13.334V6.66667H12.0007Z"
      fill="#646464"
    />
  </svg>
);

export const InviteOperator = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M9.33268 9.50129V10.8946C8.7293 10.6813 8.08353 10.6159 7.44962 10.7038C6.8157 10.7918 6.21215 11.0306 5.68964 11.4001C5.16712 11.7696 4.74091 12.2592 4.44679 12.8276C4.15267 13.396 3.99923 14.0266 3.99935 14.6666H2.66602C2.66571 13.8525 2.85179 13.0491 3.20999 12.318C3.5682 11.5869 4.08903 10.9475 4.73256 10.4489C5.3761 9.95018 6.12525 9.60545 6.92262 9.44109C7.71999 9.27673 8.5444 9.29776 9.33268 9.50129ZM7.99935 8.66663C5.78935 8.66663 3.99935 6.87663 3.99935 4.66663C3.99935 2.45663 5.78935 0.666626 7.99935 0.666626C10.2093 0.666626 11.9993 2.45663 11.9993 4.66663C11.9993 6.87663 10.2093 8.66663 7.99935 8.66663ZM7.99935 7.33329C9.47268 7.33329 10.666 6.13996 10.666 4.66663C10.666 3.19329 9.47268 1.99996 7.99935 1.99996C6.52602 1.99996 5.33268 3.19329 5.33268 4.66663C5.33268 6.13996 6.52602 7.33329 7.99935 7.33329ZM11.9993 11.3333V9.33329H13.3327V11.3333H15.3327V12.6666H13.3327V14.6666H11.9993V12.6666H9.99935V11.3333H11.9993Z"
      fill="#646464"
    />
  </svg>
);

export const ChangeStore = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M12 9V11.3333H10V12.6667H12V15L15.3333 12L12 9Z" fill="#646464" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3102 7.33337C14.5426 6.93063 14.6673 6.47121 14.6673 6.00004V2.00004C14.6673 1.82323 14.5971 1.65366 14.4721 1.52864C14.347 1.40361 14.1775 1.33337 14.0007 1.33337H2.00065C1.82384 1.33337 1.65427 1.40361 1.52925 1.52864C1.40422 1.65366 1.33398 1.82323 1.33398 2.00004V6.00004C1.33394 6.64961 1.571 7.27686 2.00065 7.76404V14C2.00065 14.1769 2.07089 14.3464 2.19591 14.4714C2.32094 14.5965 2.49051 14.6667 2.66732 14.6667L8.66732 14.6667V13.3334H3.33398V8.58271C3.81069 8.7065 4.31241 8.69552 4.78324 8.55097C5.25407 8.40642 5.67553 8.134 6.00065 7.76404C6.25091 8.04776 6.55868 8.27498 6.9035 8.43062C7.24833 8.58625 7.62233 8.66673 8.00065 8.66671C8.37897 8.66673 8.75297 8.58625 9.0978 8.43062C9.44263 8.27498 9.75039 8.04776 10.0007 7.76404C10.3267 8.13276 10.7482 8.40431 11.2187 8.54876C11.4733 8.62691 11.7368 8.66622 12.0007 8.66648V7.33337H14.3102ZM12.0007 7.33337C12.3543 7.33337 12.6934 7.1929 12.9435 6.94285C13.1935 6.6928 13.334 6.35366 13.334 6.00004V2.66671H2.66732V6.00004C2.66732 6.35366 2.80779 6.6928 3.05784 6.94285C3.30789 7.1929 3.64703 7.33337 4.00065 7.33337C4.35427 7.33337 4.69341 7.1929 4.94346 6.94285C5.19351 6.6928 5.33398 6.35366 5.33398 6.00004C5.33398 5.82323 5.40422 5.65366 5.52925 5.52864C5.65427 5.40361 5.82384 5.33337 6.00065 5.33337C6.17746 5.33337 6.34703 5.40361 6.47205 5.52864C6.59708 5.65366 6.66732 5.82323 6.66732 6.00004C6.66732 6.35366 6.80779 6.6928 7.05784 6.94285C7.30789 7.1929 7.64703 7.33337 8.00065 7.33337C8.35427 7.33337 8.69341 7.1929 8.94346 6.94285C9.19351 6.6928 9.33398 6.35366 9.33398 6.00004C9.33398 5.82323 9.40422 5.65366 9.52925 5.52864C9.65427 5.40361 9.82384 5.33337 10.0007 5.33337C10.1775 5.33337 10.347 5.40361 10.4721 5.52864C10.5971 5.65366 10.6673 5.82323 10.6673 6.00004C10.6673 6.35366 10.8078 6.6928 11.0578 6.94285C11.3079 7.1929 11.647 7.33337 12.0007 7.33337Z"
      fill="#646464"
    />
    <path
      d="M10.6673 8.30944C11.0727 8.54349 11.5326 8.66671 12.0007 8.66671C12.4687 8.66671 12.9286 8.54349 13.334 8.30944C13.7394 8.07539 14.076 7.73876 14.3101 7.33337C14.5441 6.92799 14.6673 6.46814 14.6673 6.00004H13.334C13.334 6.23409 13.2724 6.46402 13.1554 6.66671C13.0383 6.8694 12.87 7.03772 12.6673 7.15474C12.4646 7.27177 12.2347 7.33337 12.0007 7.33337C11.7666 7.33337 11.5367 7.27177 11.334 7.15474L10.6673 8.30944Z"
      fill="#646464"
    />
  </svg>
);

export const RestartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M12.3587 13.0447C11.149 14.093 9.60134 14.669 8.00065 14.6667C4.31865 14.6667 1.33398 11.682 1.33398 8.00004C1.33398 4.31804 4.31865 1.33337 8.00065 1.33337C11.6827 1.33337 14.6673 4.31804 14.6673 8.00004C14.6673 9.42404 14.2207 10.744 13.4607 11.8267L11.334 8.00004H13.334C13.3339 6.77085 12.9092 5.57942 12.1318 4.6273C11.3543 3.67519 10.2719 3.02084 9.06754 2.77495C7.86319 2.52906 6.61087 2.70672 5.52244 3.27788C4.434 3.84905 3.57627 4.77865 3.09433 5.90943C2.6124 7.04021 2.53585 8.30275 2.87765 9.48347C3.21944 10.6642 3.95858 11.6906 4.97004 12.3891C5.9815 13.0876 7.20318 13.4152 8.42841 13.3166C9.65365 13.218 10.8072 12.6993 11.694 11.848L12.3587 13.0447Z"
      fill="#646464"
    />
  </svg>
);

export const LogoutIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M3.33268 14.6667C3.15587 14.6667 2.9863 14.5965 2.86128 14.4714C2.73625 14.3464 2.66602 14.1769 2.66602 14V2.00004C2.66602 1.82323 2.73625 1.65366 2.86128 1.52864C2.9863 1.40361 3.15587 1.33337 3.33268 1.33337H12.666C12.8428 1.33337 13.0124 1.40361 13.1374 1.52864C13.2624 1.65366 13.3327 1.82323 13.3327 2.00004V4.00004H11.9993V2.66671H3.99935V13.3334H11.9993V12H13.3327V14C13.3327 14.1769 13.2624 14.3464 13.1374 14.4714C13.0124 14.5965 12.8428 14.6667 12.666 14.6667H3.33268ZM11.9993 10.6667V8.66671H7.33268V7.33337H11.9993V5.33337L15.3327 8.00004L11.9993 10.6667Z"
      fill="#646464"
    />
  </svg>
);

export const TradeInIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
    <path
      d="M13.6673 11.3333V2.66667L12.334 0H1.66732L0.333984 2.66933V11.3333C0.333984 11.5101 0.404222 11.6797 0.529246 11.8047C0.654271 11.9298 0.82384 12 1.00065 12H13.0007C13.1775 12 13.347 11.9298 13.4721 11.8047C13.5971 11.6797 13.6673 11.5101 13.6673 11.3333ZM1.66732 4H12.334V10.6667H1.66732V4ZM2.49132 1.33333H11.51L12.1767 2.66667H1.82532L2.49132 1.33333ZM9.00065 5.33333H5.00065V6.66667H9.00065V5.33333Z"
      fill="#646464"
    />
  </svg>
);
