import Typograph from '@/components/typography';

const Footer = () => {
  const handleCrispHelp = () => {
    $crisp.push(['do', 'chat:open', []]);
  };

  return (
    <div className="flex w-full items-center justify-center gap-2 border border-neutral-3 px-4 py-3">
      <CallerIcon />

      <Typograph.Span variant="medium-body">
        <Typograph.Span variant="link-gray" onClick={handleCrispHelp}>
          Fale conosco
        </Typograph.Span>{' '}
        através do chat.
      </Typograph.Span>
    </div>
  );
};

const CallerIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_5356_29394)">
      <path
        d="M14.0003 5.33294C14.3539 5.33294 14.6931 5.47342 14.9431 5.72347C15.1932 5.97351 15.3337 6.31265 15.3337 6.66627V9.33294C15.3337 9.68656 15.1932 10.0257 14.9431 10.2758C14.6931 10.5258 14.3539 10.6663 14.0003 10.6663H13.2923C13.1299 11.9552 12.5027 13.1405 11.5283 13.9998C10.5539 14.859 9.29944 15.3331 8.00033 15.3329V13.9996C9.06119 13.9996 10.0786 13.5782 10.8288 12.828C11.5789 12.0779 12.0003 11.0605 12.0003 9.99961V5.99961C12.0003 4.93874 11.5789 3.92133 10.8288 3.17118C10.0786 2.42104 9.06119 1.99961 8.00033 1.99961C6.93946 1.99961 5.92204 2.42104 5.1719 3.17118C4.42175 3.92133 4.00033 4.93874 4.00033 5.99961V10.6663H2.00033C1.6467 10.6663 1.30757 10.5258 1.05752 10.2758C0.807468 10.0257 0.666992 9.68656 0.666992 9.33294V6.66627C0.666992 6.31265 0.807468 5.97351 1.05752 5.72347C1.30757 5.47342 1.6467 5.33294 2.00033 5.33294H2.70833C2.87101 4.0442 3.49838 2.85912 4.47271 2.00006C5.44704 1.14099 6.70136 0.666992 8.00033 0.666992C9.29929 0.666992 10.5536 1.14099 11.5279 2.00006C12.5023 2.85912 13.1296 4.0442 13.2923 5.33294H14.0003ZM5.17366 10.5229L5.88033 9.39227C6.51568 9.79036 7.25056 10.0009 8.00033 9.99961C8.75009 10.0009 9.48497 9.79036 10.1203 9.39227L10.827 10.5229C9.97988 11.0538 9.00004 11.3346 8.00033 11.3329C7.00062 11.3346 6.02077 11.0538 5.17366 10.5229Z"
        fill="#646464"
      />
    </g>
    <defs>
      <clipPath id="clip0_5356_29394">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Footer;
