export enum USER_ROLE {
  STORE_OPERATOR = 'STORE_OPERATOR',
  STORE_OPERATOR_ADMIN = 'STORE_OPERATOR_ADMIN',
  ADMIN = 'ADMIN',
}

export const RETAILER_BANNER_HEIGHT = {
  base: 64,
  md: 44,
};
